import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { Wrapper, Filters, CityItem, CityPickerWrapper, CityPickerRangeWrapper, SliderArrow } from '../styled';
import { SortByController } from '../shared/SortByController';
import { useNearCity } from '../provider';
import { DistancePicker } from '../shared/DistancePicker';
import { MarginWrapper } from '@theme/shared/wrappers';
import { runOnClient } from '@shared/utils/is-client';
import { requireNotNull } from '@shared/utils/require-not-null';
import { totalBy } from '@shared/utils/helpers';
import { Icon } from '@components/Icon';
import { Text } from '@theme/shared/text';
import { Nullable } from 'types';

interface ArrowParams {
  disableLeft?: boolean;
  disableRight?: boolean;
}

const slideManualMaxStepPx = 200;
let interval: Nullable<NodeJS.Timeout> = null;

const getCompanyTerm = (count: number) => {
  if (count === 1) {
    return 'компания';
  }

  if (count < 5) {
    return 'компании';
  }

  return 'компаний';
};

export const CityPicker = () => {
  const nearCityProvider = useNearCity();
  const sliderWrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const sliderRef = useRef<Nullable<HTMLDivElement>>(null);
  const [arrowParams, setArrowParams] = useState<ArrowParams>({});
  const windowWidth = useWindowWidth();

  const calculateArrowParams = useCallback(() => {
    if (!sliderWrapperRef.current) return;

    const cityNodes = requireNotNull(sliderRef.current).querySelectorAll('[data-city-item]');
    const sliderWidth = totalBy(Array.from(cityNodes), n => n.getBoundingClientRect().width);
    const { width: wrapperWidth } = sliderWrapperRef.current.getBoundingClientRect();
    const currentScrollOffset = requireNotNull(sliderWrapperRef.current).scrollLeft;

    setArrowParams({
      disableLeft: currentScrollOffset === 0,
      disableRight:
        wrapperWidth > sliderWidth || wrapperWidth + currentScrollOffset + (cityNodes.length - 1) === sliderWidth,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, nearCityProvider.cityOptions]);

  useEffect(() => {
    calculateArrowParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollHandler = useCallback(() => {
    calculateArrowParams();
  }, [calculateArrowParams]);

  useEffect(() => {
    if (!sliderWrapperRef.current) return;

    const element = sliderWrapperRef.current;
    runOnClient(() => {
      element.addEventListener('scroll', scrollHandler);
    });

    return () => {
      runOnClient(() => {
        element.removeEventListener('scroll', scrollHandler);
      });
    };
  }, [scrollHandler]);

  const manualNavigate = (direction: 'left' | 'right') => {
    interval && clearInterval(interval);
    let total = slideManualMaxStepPx;
    interval = setInterval(() => {
      if (!sliderWrapperRef.current) {
        return;
      }

      if (total > 0) {
        total -= 10;
        if (direction === 'left') {
          sliderWrapperRef.current.scrollLeft -= 10;
        } else {
          sliderWrapperRef.current.scrollLeft += 10;
        }
      } else {
        interval && clearInterval(interval);
      }
    }, 10);
  };

  const handleNext = () => {
    manualNavigate('right');
  };

  const handlePrev = () => {
    manualNavigate('left');
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    nearCityProvider.changeFilterState({
      cityId: Number(e.currentTarget.dataset.cityItem),
    });
  };

  return (
    <MarginWrapper marginTop="m" style={{ position: 'relative' }}>
      <SliderArrow isDisabled={Boolean(arrowParams.disableLeft)} variant="left" onClick={handlePrev}>
        <Icon size="s" name="cheveron-left.outline" />
      </SliderArrow>
      <CityPickerWrapper ref={sliderWrapperRef}>
        <CityPickerRangeWrapper ref={sliderRef}>
          {nearCityProvider.cityOptions &&
            nearCityProvider.cityOptions.map(city => (
              <CityItem key={city.id} isSelected={false} data-city-item={city.id} onClick={handleClick} tabIndex={0}>
                <MarginWrapper marginBottom="xs">{city.label}</MarginWrapper>
                <div>
                  <Text.Secondary>≈ {city.distance} км</Text.Secondary>
                </div>
                <div>
                  <Text.Secondary>
                    {city.companyCount} {getCompanyTerm(city.companyCount)}
                  </Text.Secondary>
                </div>
              </CityItem>
            ))}
        </CityPickerRangeWrapper>
      </CityPickerWrapper>
      <SliderArrow isDisabled={Boolean(arrowParams.disableRight)} variant="right" onClick={handleNext}>
        <Icon size="s" name="cheveron-right.outline" />
      </SliderArrow>
    </MarginWrapper>
  );
};

export const SearchNearCityPickerDesktop = () => {
  const nearCity = useNearCity();

  if (!nearCity.cityOptions || nearCity.cityOptions.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Filters>
        <div>
          <Text.Header4>Близлежащие города</Text.Header4>
        </div>
        <DistancePicker />
        <SortByController />
      </Filters>
      <CityPicker />
    </Wrapper>
  );
};
