import styled from 'styled-components';
import { flexFullCenter } from '@theme/shared/utils';

export const HintIcon = styled.div`
  ${flexFullCenter()}
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin: auto 0;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const HintIconColors = styled.div`
  ${flexFullCenter()}
  cursor: pointer;
  margin: auto 0;
  background: #fff;
  z-index: 2;
  color: ${({ theme }) => theme.palette.system.warning};
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -2px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
