import React from 'react';
import { CitySortTypeDesc, useNearCity } from '../../provider';
import { LabelWrapper, Wrapper, Option } from './styled';
import { useBoolean } from '@shared/hooks/use-boolean';
import { ContextMenu } from '@components/ContextMenu';
import { Icon } from '@components/Icon';
import { MarginWrapper } from '@theme/shared/wrappers';

const typeDictionary: { [key in CitySortTypeDesc]: string } = {
  byCompaniesCount: 'По количеству компаний',
  byDistance: 'По приближенности',
};

export const SortByController = () => {
  const visibleOptions = useBoolean();
  const nearCity = useNearCity();

  const value = nearCity.filterState.sortType;

  const handleSelect = (type: CitySortTypeDesc) => {
    nearCity.changeFilterState({
      sortType: type,
    });
    visibleOptions.setFalse();
  };

  return (
    <Wrapper>
      <LabelWrapper onClick={visibleOptions.setTrue} tabIndex={0} isVisibleMenu={visibleOptions.value}>
        <Icon color="main.primary" size="sm" name="switch-vertical.outline" />
        <MarginWrapper marginLeft="xxs">{typeDictionary[value]}</MarginWrapper>
      </LabelWrapper>
      {visibleOptions.value && (
        <ContextMenu onClose={visibleOptions.setFalse}>
          {Object.keys(typeDictionary).map(key => (
            <Option
              key={key}
              isSelected={key === value}
              tabIndex={0}
              onClick={() => handleSelect(key as CitySortTypeDesc)}
            >
              {(typeDictionary as any)[key]}
            </Option>
          ))}
        </ContextMenu>
      )}
    </Wrapper>
  );
};
