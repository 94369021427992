import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const borderRadius = '5px';

export const RangeWrapper = styled.div`
  display: flex;
`;

export const RangeItem = styled.div<{ isSelected: boolean }>`
  padding: ${({ theme }) => theme.spaces.xxs} ${({ theme }) => theme.spaces.xs};
  border: 1px solid ${({ theme }) => theme.palette.grey.light};
  position: relative;
  background: ${({ theme }) => theme.palette.grey.white};

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => rgba(theme.palette.main.primary, 0.02)};
  }

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      pointer-events: none;
      border-color: ${theme.palette.main.primary};
      color: ${theme.palette.main.primary};
      z-index: 1;
    `}
  &:first-child {
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
  }

  &:last-child {
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }
`;
