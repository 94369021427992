import styled from 'styled-components';
import { CardWrapper } from '@theme/shared/components';

export const WrapperForm = styled(CardWrapper)`
  overflow: initial;
`;

const Form = styled.div`
  /* overflow: hidden; */
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  .submit-btn {
    height: 58px;
    box-shadow: none;
    border-radius: 0;
  }

  .MuiFilledInput-root {
    border-radius: 0;
    border-color: ${({ theme }) => theme.colors.gray};
  }

  > * {
    border-radius: 0;
    width: 100%;

    :not(:last-child) {
      background: #fff;
    }

    :first-child .MuiFilledInput-root {
      border-top-left-radius: ${({ theme }) => theme.shape.borderRadius};
      border-top-right-radius: ${({ theme }) => theme.shape.borderRadius};
    }

    :last-child {
      border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius};
      border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius};
    }
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    display: flex;
    flex-wrap: wrap;

    > * {
      width: calc(50%);

      :first-child .MuiFilledInput-root {
        border-top-right-radius: 0;
      }

      :nth-child(2) .MuiFilledInput-root {
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius};
      }

      :last-child {
        width: 100%;
      }
    }
  `}

  ${({ theme }) => theme.utils.media.largeDesktop`
    > * {
      :last-child  {
        border-bottom-left-radius: 0;
      }
    }
  `}
`;

export const ShortForm = styled(Form)`
  ${({ theme }) => theme.utils.media.largeDesktop`
    > * {
      width: 22% !important;

      :first-child .MuiFilledInput-root {
        border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius};
      }

      :nth-child(2) .MuiFilledInput-root {
        border-top-right-radius: 0;
      }

      :nth-child(3) .MuiFilledInput-root {
        border-bottom-left-radius: 0;
      }

      :last-child  {
        width: 12% !important;
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius};
      }
    }
  `}
`;

export const FullForm = styled(Form)`
  ${({ theme }) => theme.utils.media.largeDesktop`
    position: relative;

    > * {
      width: 20%;

      :nth-child(6),
      :nth-child(7),
      :nth-child(8) {
        width: 30%;
      }

      :last-child {
        width: 10%;
      }

      :nth-child(2) .MuiFilledInput-root,
      :nth-child(3) .MuiFilledInput-root,
      :nth-child(7) .MuiFilledInput-root {
        border-radius: 0;
      }

      :nth-child(5) .MuiFilledInput-root {
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius};
      }

      :nth-child(6) .MuiFilledInput-root {
        border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius};
      }
    }
  `}
`;

export const SearchParamsStack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

export const ToggleStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  column-gap: ${({ theme }) => theme.spaces.s};
  width: max-content;

  ${({ theme }) => theme.utils.media.largeDesktop`
    flex-direction: row;
  `}
`;

export const StrictSearchHint = styled.div`
  display: flex;
  width: auto;
  padding-right: ${({ theme }) => theme.spaces.xs};
  margin-right: ${({ theme }) => theme.spaces.s};

  .MuiFormControlLabel-root {
    margin-right: ${({ theme }) => theme.spaces.xxs};
  }
`;

export const ControlsWrap = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: ${({ theme }) => theme.spaces.m};
  width: min-content;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-left: auto;
  `}
`;

export const ClearSearchBtn = styled.button`
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.link};
  white-space: nowrap;

  :disabled {
    color: ${({ theme }) => theme.palette.grey.middle};
    cursor: initial;
  }

  svg {
    height: inherit;
    margin-right: 10px;
  }
`;
