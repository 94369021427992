import { Values } from './types';
import { NearCityFilterState } from 'screens/Search/SearchNearCityPicker/provider';

export const initialValues: Values = {
  region: null,
  name: '',
  license: '',
  warningClass: [],
  typeMove: [],
  inn: '',
  placeActivity: null,
  fkko: [],
  typesMoveAsAnd: true,
  hasLicense: true,
};

export const nearCityFilterInitial: NearCityFilterState = {
  distance: 2,
  sortType: 'byCompaniesCount',
};
