import React from 'react';
import { CityDistanceTypeDesc, nearCityDistanceTypes, useNearCity } from '../../provider';
import { RangeItem, RangeWrapper } from '../styled';

export const DistancePicker = () => {
  const nearCity = useNearCity();
  const handleChange = (type: CityDistanceTypeDesc) => {
    nearCity.changeFilterState({
      distance: type,
    });
  };
  return (
    <div>
      <RangeWrapper>
        {nearCityDistanceTypes.map(i => (
          <RangeItem
            key={i}
            onClick={() => handleChange(i)}
            isSelected={nearCity.filterState.distance === i}
          >{`+${i}00 км`}</RangeItem>
        ))}
      </RangeWrapper>
    </div>
  );
};
