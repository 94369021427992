const objectStringify = (value: any) => (typeof value === 'object' ? JSON.stringify(value) : value);

export const objectToQuery = (obj: object) =>
  Object.entries(obj)
    .reduce((acc, [key, value]) => {
      return value || typeof value === 'boolean'
        ? acc.concat(`${key}=${encodeURIComponent(objectStringify(value))}`)
        : acc;
    }, <any>[])
    .join('&');

export const parseQueryObject = <K>(query: object): K => {
  return Object.entries(query).reduce((acc, [key, value]) => {
    try {
      // @ts-ignore
      acc[key] = JSON.parse(value);
    } catch (err) {
      console.error(err);
      // @ts-ignore
      acc[key] = value;
    }
    return acc;
  }, {} as K);
};
