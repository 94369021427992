import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  button:first-child {
    border-radius: 2px 0px 0px 2px;
    border-right: 0;
  }

  button:last-child {
    border-radius: 0px 2px 2px 0px;
    border-left: 0;
  }
`;

interface ButtonSwitcherProps {
  active: boolean;
}

export const ButtonSwitcher = styled.button<ButtonSwitcherProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  background: ${({ active, theme }) => (active ? theme.palette.main.primary : theme.palette.grey.white)};
  color: ${({ active, theme }) => (active ? theme.palette.grey.white : theme.palette.text.secondary)};
  border-color: ${({ active, theme }) => (active ? theme.palette.main.primary : theme.palette.text.secondary)};

  width: 46px;
  height: 40px;
`;
