import styled, { css } from 'styled-components';
import { RangeItem, RangeWrapper } from './shared/styled';

export const Wrapper = styled.div``;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    margin-top: ${({ theme }) => theme.spaces.xs};
  }

  > div:not(:last-child) {
    margin-right: ${({ theme }) => theme.spaces.s};
  }
`;

export const CityPickerWrapper = styled.div`
  max-width: 100%;
  position: relative;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface ArrowProps {
  variant: 'left' | 'right';
  isDisabled: boolean;
}

export const SliderArrow = styled.div<ArrowProps>`
  position: absolute;
  top: calc(50% - 20px);
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadow.base};
  transition: opacity 0.1s ${({ theme }) => theme.animate.base};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.grey.white};
  color: ${({ theme }) => theme.palette.main.primary};
  opacity: 0.9;
  border: 1px solid transparent;

  &:hover {
    opacity: 1;
    cursor: pointer;
    border-color: ${({ theme }) => theme.palette.main.primary};
  }

  ${({ variant }) => {
    if (variant === 'left') {
      return css`
        left: -10px;
      `;
    }

    if (variant === 'right') {
      return css`
        right: -10px;
      `;
    }

    return '';
  }}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    `}
`;

export const CityPickerRangeWrapper = styled(RangeWrapper)`
  width: 0;
  margin-left: 0;
  transition: margin-left 0.1s ${({ theme }) => theme.animate.base};
`;

export const CityItem = styled(RangeItem)`
  min-width: 190px;
`;
