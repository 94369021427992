import React, { useCallback, useMemo } from 'react';

export type CitySortTypeDesc = 'byCompaniesCount' | 'byDistance';
export type CityDistanceTypeDesc = 2 | 4 | 6;

export interface CityItem {
  label: string;
  distance: number;
  companyCount: number;
  id: number;
}

export interface NearCityFilterState {
  cityId?: number;
  sortType: CitySortTypeDesc;
  distance: CityDistanceTypeDesc;
}

export interface NearCityProviderProps {
  cityOptions?: CityItem[];
  filterState: NearCityFilterState;

  onChangeFilterState(newState: NearCityFilterState): void;
}

interface ContextProps {
  filterState: NearCityFilterState;

  changeFilterState(newState: Partial<NearCityFilterState>): void;

  cityOptions?: NearCityProviderProps['cityOptions'];
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const NearCityProvider: React.FC<NearCityProviderProps> = ({
  children,
  onChangeFilterState,
  cityOptions,
  filterState,
}) => {
  const handleChangeFilterState = useCallback(
    (newState: Partial<NearCityFilterState>) => {
      const state: NearCityFilterState = { ...filterState, ...newState };
      onChangeFilterState(state);
    },
    [filterState, onChangeFilterState]
  );

  const value = useMemo((): ContextProps => {
    return {
      cityOptions,
      changeFilterState: handleChangeFilterState,
      filterState,
    };
  }, [cityOptions, handleChangeFilterState, filterState]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useNearCity = () => React.useContext(Context) as ContextProps;

export const nearCityDistanceTypes: CityDistanceTypeDesc[] = [2, 4, 6];
