import React, { FC, ReactNode } from 'react';
import { TooltipProps } from '@material-ui/core';
import { HintIcon, HintIconColors } from './styled';
import { Icon } from '@components/Icon';
import { Tooltip } from '@components/Tooltip';

interface Props {
  title: NonNullable<ReactNode>;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;

  isColors?: boolean;
}

export const Hint: FC<Props> = ({ title, tooltipProps, isColors }) => {
  return (
    <Tooltip title={title} {...tooltipProps}>
      {isColors ? (
        <HintIconColors>
          <Icon name="question-mark-circle.outline" />
        </HintIconColors>
      ) : (
        <HintIcon>
          <Icon name="question-mark-circle.outline" />
        </HintIcon>
      )}
    </Tooltip>
  );
};
