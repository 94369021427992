import { Wrapper, ButtonSwitcher } from './styled';
import { Icon } from '@components/Icon';

interface Props {
  layoutView: `"grid"` | `"location"`;
  onSelect: (type: Props['layoutView']) => void;
}

export const ViewSwitcher = ({ layoutView, onSelect }: Props) => {
  const handleSelect = (type: Props['layoutView']) => {
    onSelect(type);
  };

  return (
    <Wrapper>
      <ButtonSwitcher active={layoutView === `"grid"`} onClick={() => handleSelect(`"grid"`)}>
        <Icon size="m" name="view-grid.outline" />
      </ButtonSwitcher>
      <ButtonSwitcher active={layoutView === `"location"`} onClick={() => handleSelect(`"location"`)}>
        <Icon size="m" name="location-marker.outline" />
      </ButtonSwitcher>
    </Wrapper>
  );
};
