import { SearchNearCityPickerDesktop } from './desktop';
import { NearCityProviderProps, NearCityProvider } from './provider';

interface Props {
  provider: NearCityProviderProps;
}

export const SearchNearCityPicker = ({ provider }: Props) => {
  return (
    <NearCityProvider {...provider}>
      <SearchNearCityPickerDesktop />
    </NearCityProvider>
  );
};
