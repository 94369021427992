import { FormikProvider, Form, useFormik } from 'formik';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useRouter } from 'next/dist/client/router';
import { AbortController } from 'node-abort-controller';
import Autocomplete from '../fields/Autocomplete';
import { NearCityFilterState } from '../../../screens/Search/SearchNearCityPicker/provider';
import { SearchNearCityPicker } from '../../../screens/Search/SearchNearCityPicker';
import {
  FullForm,
  ShortForm,
  WrapperForm,
  SearchParamsStack,
  StrictSearchHint,
  ClearSearchBtn,
  ToggleStack,
  ControlsWrap,
} from './styled';
import { initialValues, nearCityFilterInitial } from './initial-values';
import { Values } from './types';
import { getFormValues } from './helpers';
import { TechProblems } from '@lib/Components/SearchForm/TechProblems';
import TextField from '@lib/Components/fields/TextField';
import Select from '@lib/Components/fields/Select';
import { createAsyncAutocomplete } from '@lib/Components/SearchForm/hooks/autocomplete/useAsyncAutocomplete';
import { routes } from 'routes';
import { objectToQuery, parseQueryObject } from 'shared/utils/link-utils';
import { CompanyResponseTotalRecords, WarningClass } from '@http/models/api/company';
import { Icon } from '@components/Icon';
import { typesMoveOptions } from '@constants/company';
import {
  FkkoAutocompleteRequestRunner,
  mapRegionToAutocompleteOption,
  RegionAutocompleteRequestRunner,
} from '@http/autocomplete';
import { FormikSwitch, NativeSwitch } from '@lib/Components/fields/Switch';
import { Button } from '@components/Button';
import { getRegionById } from '@http/endpoints/regions';
import { openModalIfHaveId } from '@shared/utils/openModalIfHaveId';
import { LAYOUT_VIEW, MODAL_COMPANY_INFO } from 'types/routesQueryNames';
import { useLayoutView } from 'providers/layout-view-provider/LayoutViewProvider';
import { ViewSwitcher } from '@components/ViewSwithcer/ViewSwitcher';
import { Hint } from '@components/Hint';
import { Nullable } from 'types';
import { Option } from '@lib/Components/fields/Autocomplete/types';

const isTechProblenms = false;

const warningClass = [WarningClass.First, WarningClass.Second, WarningClass.Third, WarningClass.Fourth].map(en => ({
  label: en.toString(),
  value: en,
}));

interface Props {
  queryParams?: object;
  results?: CompanyResponseTotalRecords;

  onSubmit(values: Values, nearCityFilter: NearCityFilterState, page?: number | string): void;
}

const useCompanyRegionsAutocomplete = createAsyncAutocomplete(new RegionAutocompleteRequestRunner());
const usePlaceRegionsAutocomplete = createAsyncAutocomplete(new RegionAutocompleteRequestRunner());
const useFkkoAutocomplete = createAsyncAutocomplete(new FkkoAutocompleteRequestRunner());

const SearchForm: React.FC<Props> = ({ queryParams = {}, onSubmit, results }) => {
  const router = useRouter();
  const [isFullForm, setFullForm] = useState(false);
  const [disabledCheckbox, setDisabledCheckbox] = useState(false);
  const [nearCityFilter, setNearCityFilter] = useState<NearCityFilterState>(nearCityFilterInitial);
  const layoutContext = useLayoutView();
  const controller = useRef(new AbortController());

  const form = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    async onSubmit(values: Values) {
      await router.push(
        routes.search(objectToQuery({ ...values, [LAYOUT_VIEW]: layoutContext?.layoutView || `"grid"` }))
      );
    },
  });

  const isInitialMount = useRef(true);
  const prevModalCompanyShowState = useRef(!!openModalIfHaveId(router.query, MODAL_COMPANY_INFO));
  const prevLayoutViewState = useRef(layoutContext?.layoutView || `"grid"`);

  useEffect(() => {
    if (router.route !== routes.search()) return;

    const queryValues = getFormValues(initialValues, queryParams);

    form.setValues(queryValues);

    onSubmit(queryValues, nearCityFilter, (router.query.page as string) || 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Запрос данных при изменении урла, например, пагинация, при открытии / закрытии модалки
  useEffect(() => {
    if (router.route !== routes.search()) return;

    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (router.query[LAYOUT_VIEW] && prevLayoutViewState.current !== router.query[LAYOUT_VIEW]) {
      prevLayoutViewState.current = router.query[LAYOUT_VIEW] as typeof layoutContext.layoutView;
      return;
    }

    if (!!openModalIfHaveId(router.query, MODAL_COMPANY_INFO)) {
      prevModalCompanyShowState.current = true;
      return;
    }

    if (prevModalCompanyShowState.current) {
      prevModalCompanyShowState.current = false;
      return;
    }

    const query = parseQueryObject<any>(router.query);
    let region: Nullable<Option> = null;
    if (form.values.region?.value !== query.region?.value) {
      region = query.region
        ? {
            hint: query.region.hint,
            label: query.region.label,
            value: query.region.value,
          }
        : null;
      form.setFieldValue('region', region);
    }
    const isInitialRequest = Object.keys(query).length === 0;
    const values = region
      ? { ...form.values, region }
      : {
          ...form.values,
          region: isInitialRequest ? null : form.values.region,
        };

    onSubmit(values, isInitialRequest ? nearCityFilterInitial : nearCityFilter, (router.query.page as string) || 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  // Если есть поля из расширенной формы, показываем ее и дизейблим switch, переключающий тип формы
  useEffect(() => {
    const { license, inn, warningClass, placeActivity } = form.values;

    if (license || inn || warningClass.length || placeActivity) {
      setFullForm(true);
      setDisabledCheckbox(true);
    } else {
      setDisabledCheckbox(false);
    }
  }, [form.values]);

  const handleChangeNearCityFilter = useCallback(
    async (newFilter: NearCityFilterState) => {
      setNearCityFilter({ ...newFilter, cityId: undefined });
      const cityId = newFilter.cityId;
      if (cityId) {
        const region = await getRegionById(cityId, controller.current);
        await form.setFieldValue('region', mapRegionToAutocompleteOption(region));
        await form.submitForm();
      } else {
        onSubmit(form.values, newFilter, 1);
      }
    },
    // eslint-disable-next-line
    [form.values, setNearCityFilter, onSubmit, form]
  );

  const [companyRegions, companyRegionsIsLoading, handleChangeCompanyRegion] = useCompanyRegionsAutocomplete();
  const [placeRegions, placeRegionsIsLoading, handleChangePlaceRegion] = usePlaceRegionsAutocomplete();
  const [ffkoOptions, fkkoAutocompleteIsLoading, handleFkkoQuery] = useFkkoAutocomplete();

  const formFields = {
    name: <TextField name="name" label="Наименование компании" />,
    warningClass: <Select name="warningClass" options={warningClass} label="Класс опасности отхода" multiple />,
    typeMove: <Select name="typeMove" options={typesMoveOptions} label="Вид работ" multiple />,
    inn: <TextField name="inn" label="ИНН" type="number" />,
    license: <TextField name="license" label="Номер лицензии" />,
    region: (
      <Autocomplete
        name="region"
        label="Регион компании"
        loading={companyRegionsIsLoading}
        options={companyRegions}
        onInputChange={handleChangeCompanyRegion}
        filterOptions={(x, _) => x}
      />
    ),
    placeActivity: (
      <Autocomplete
        name="placeActivity"
        label="Место осущ. деятельности"
        loading={placeRegionsIsLoading}
        options={placeRegions}
        onInputChange={handleChangePlaceRegion}
      />
    ),
    fkko: (
      <Autocomplete
        multiple
        name="fkko"
        label="Код ФККО или название"
        loading={fkkoAutocompleteIsLoading}
        options={ffkoOptions}
        onInputChange={handleFkkoQuery}
        filterOptions={(x, _) => x}
      />
    ),
    submitButton: (
      <Button type="submit" className="submit-btn">
        Найти
      </Button>
    ),
  };

  return (
    <>
      <FormikProvider value={form}>
        <Form>
          <SearchParamsStack>
            <ToggleStack>
              <StrictSearchHint>
                <FormikSwitch name="typesMoveAsAnd" label="Строгий поиск" />
                <Hint
                  tooltipProps={{ arrow: true }}
                  title="При отключении данной опции поиск не будет учитывать строгое совпадение по нескольким выбранным
                        видам работ"
                />
              </StrictSearchHint>
              <FormikSwitch name="hasLicense" label="Есть действущие лицензии" />
              <NativeSwitch
                disabled={disabledCheckbox}
                checked={isFullForm}
                onChange={() => setFullForm(!isFullForm)}
                label="Расширенный поиск"
              />
            </ToggleStack>
            <ControlsWrap>
              <ClearSearchBtn disabled={!form.dirty} onClick={form.handleReset} type="reset">
                <Icon name="trash.outline" />
                Очистить поиск
              </ClearSearchBtn>
              {layoutContext && (
                <ViewSwitcher layoutView={layoutContext.layoutView} onSelect={layoutContext.setLayoutView} />
              )}
            </ControlsWrap>
          </SearchParamsStack>
          <WrapperForm>
            {isTechProblenms && <TechProblems />}
            {isFullForm ? (
              <FullForm>
                {formFields.fkko}
                {formFields.license}
                {formFields.inn}
                {formFields.warningClass}
                {formFields.typeMove}
                {formFields.name}
                {formFields.placeActivity}
                {formFields.region}
                {formFields.submitButton}
              </FullForm>
            ) : (
              <ShortForm>
                {formFields.fkko}
                {formFields.name}
                {formFields.typeMove}
                {formFields.region}
                {formFields.submitButton}
              </ShortForm>
            )}
          </WrapperForm>
        </Form>
      </FormikProvider>
      <SearchNearCityPicker
        provider={{
          filterState: nearCityFilter,
          onChangeFilterState: handleChangeNearCityFilter,
          cityOptions: (results?.soCloseDistance || []).map(item => ({
            label: item.title,
            distance: item.distanceKm,
            companyCount: item.count,
            id: item.id,
          })),
        }}
      />
    </>
  );
};

export default SearchForm;
