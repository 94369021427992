import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const LabelWrapper = styled.div<{ isVisibleMenu: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ isVisibleMenu }) =>
    isVisibleMenu &&
    css`
      cursor: pointer;
    `}
`;

export const Option = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 35px;
  align-items: center;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      pointer-events: none;
      color: ${theme.palette.main.primary};
    `}
`;
