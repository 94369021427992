import { OrderValues } from 'screens/Orders/browse/filter/initial-values';

export const getFormValues = <T extends Object>(initialValues: T, queryParams: object) => {
  const formValues = { ...initialValues };

  Object.keys(queryParams).forEach(key => {
    if (initialValues.hasOwnProperty(key)) {
      // @ts-ignore
      formValues[key] = queryParams[key];
    }
  });

  return formValues;
};

const isFilterOrderValues = (v: any): v is OrderValues => 'name' in v;

export const encodeURIValues = <T>(values: T): T => {
  const transformedValues = { ...values };
  if (isFilterOrderValues(transformedValues) && transformedValues['name']) {
    transformedValues['name'] = encodeURIComponent(transformedValues['name']);
  }

  return transformedValues;
};

export const decodeURIValues = <T>(values: T): T => {
  const transformedValues = { ...values };
  if (isFilterOrderValues(transformedValues) && transformedValues['name']) {
    transformedValues['name'] = decodeURIComponent(transformedValues['name']);
  }

  return transformedValues;
};
