import { useRouter } from 'next/router';
import React, { useMemo, useState, FC, createContext, useContext, useEffect, useCallback } from 'react';
import { LAYOUT_VIEW } from 'types/routesQueryNames';

interface ContextProps {
  layoutView: `"grid"` | `"location"`;
  setLayoutView: (type: ContextProps['layoutView']) => void;
}

const Context = createContext<ContextProps | undefined>(undefined);

export const LayoutViewProvider: FC = ({ children }) => {
  const router = useRouter();
  const [layoutView, setLayoutView] = useState<ContextProps['layoutView']>(`"grid"`);

  const handleChangeView = useCallback((type: ContextProps['layoutView']) => {
    setLayoutView(type);
  }, []);

  useEffect(() => {
    if (router.query[LAYOUT_VIEW] && router.query[LAYOUT_VIEW] !== layoutView) {
      setLayoutView(router.query[LAYOUT_VIEW] as ContextProps['layoutView']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo((): ContextProps => {
    return {
      layoutView,
      setLayoutView: handleChangeView,
    };
  }, [layoutView, handleChangeView]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useLayoutView = () => useContext(Context) as ContextProps;
